/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, {useState} from "react";
import { Button, Collapse } from "react-bootstrap";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto_1";
import "../EstructuraBuscador/estilosEstBuscador.css";
function TextoBuscador(props) {
    const [openSt, setOpenState] = useState(true);
    const [girar, setGirar] = useState('girarArriba');
    if(localStorage.getItem('Tema') == "bg-black"){
        var iconAbaj = require("../ImgEstilos/flechaAbajoBl.png");
    }else{
        var iconAbaj = require("../ImgEstilos/flechaAbajo.png");
    };
    return (
            <div className="CajaBus">
                {props.idT > 0 && <div className="w-100 puntoBus "></div>}
            
                <div className="d-flex align-items-center mt-1"> 
                    <h2 className="m-0 titlebusqueda">{props.item.Titulo}</h2>
                    <img
                        className={"FuenteBus ml-auto  " + girar}
                        alt='bajarBoton'
                        onClick={() => {
                                if (!openSt) {
                                    setGirar('girarArriba');
                                } else {
                                    setGirar('girarAbajo');
                                }
                                setOpenState(!openSt)
                            }}
                        aria-expanded={openSt}
                        aria-controls="example-collapse-text"
                        src={iconAbaj.default}
                        />
            
                </div>
            
            
                <Collapse in={openSt}>
                    <div className=" flex-wrap align-items-center resBus2 ">
                        <div className="punt puntoBus mb-2"></div>
            
                        <EstructuraTexto
                            flechaColor={props.item.flechaColor}
                            AlingText={"AlingText w-100 pl-0  txtBus"} //
                            Titulo={props.item.Titulo}
                            Titulo2={null}
                            Rojo={props.item.subTitulo}
                            Rojo2={props.item.Hash1}
                            Verde={props.item.Hash2}
                            Azul={props.item.Hash3}
                            SubTitulo={null} //
                            mtH={"mtH tt"} //
                            texto={
                                props.item.Resumen
                            }
                            texto2={props.item.Texto}
                            color={props.item.colorTxt}
                            color1={props.item.colorTxt}
                            ImgTexto={props.imgUrl}
                            ImgTexto1={props.imgUrl2}
                            TextoBG={true}
                            txtBusc={true}
            
                            ></EstructuraTexto>
                    </div>
                </Collapse>
            </div>
            );
}

export default TextoBuscador;
