import React, { Component } from "react";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto";

class CV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsSCV: [],
        };
    }
    componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getCV.php";

        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({
                        lsSCV: resp,
                    });
                });
    }

    render() {
        return(
                <main className=" curRojo widthPer Escultura">
                    {this.state.lsSCV && this.state.lsSCV.map((item, i) => {
                                    console.log(i)
                                    if (localStorage.getItem('Lenguaje') == 'espanish') {
                                        var titulo = item.titulo_es;
                                        var texto = item.contenido_es;
                                    } else {
                                        var titulo = item.titulo_en;
                                        var texto = item.contenido_en;
                                    }
                                    if (i == 0) {
                                        return(
                                                    <EstructuraTexto
                                                        CvCss={""}
                                                        hideFlecha={true}
                                                        ImgTexto1={null}
                                                        margen={"margen"}
                                                        movCV={"movCV textobajo1"}
                                                        TextoBG={true}
                                                        Titulo={null}
                                                        Titulo2={"[Buenos Aires, 1997]"}
                                                        Rojo={null}
                                                        Rojo2={null}
                                                        cvTexto={true}
                                                        Verde={null}
                                                        Azul={null}
                                                        expanVal={true}
                                                        SubTitulo={"→ " + titulo}
                                                        texto={texto.substring(0,1265)}
                                                        texto2={texto.substring(1266,texto.length)}
                                                        color="secTxt"
                                                        color1="colorNegro"
                                                        ImgTexto={null}
                                                        primero="true"
                                                        />
                                                    );
                                        } else {
                                            return(
                                                    <EstructuraTexto
                                                        ImgTexto1={null}
                                                        margen={"margen"}
                                                        movCV={"movCV textobajo1"}
                                                        TextoBG={true}
                                                        Titulo={null}
                                                        Titulo2={null}
                                                        Rojo={null}
                                                        Rojo2={null}
                                                        Verde={null}
                                                        Azul={null}
                                                        expanVal={true}
                                                        SubTitulo={"→ " + titulo}
                                                        texto={texto}
                                                        texto2={null}
                                                        color={item.color}
                                                        color1="colorNegro"
                                                        ImgTexto={null}
                                                        />
                                                    );

                                        }
                                })}
                
                </main>
                );
    }
}

export default CV;
