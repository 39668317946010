import React, { Component } from "react";
import './fotografias.css';

class Fotografias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fotos: [],
            col1: [],
            col2: [],
            col3: [],
            col4: [],
        };
    }
    componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getFotografias.php";

        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({
                        fotos: resp,
                    });
                });
    }
    

    render() {
        {this.state.fotos && this.state.fotos.map((item, i) => {
                console.log(item.type);
                if(item.type == 'c1'){
                    this.state.col1.push(item);
                }else if(item.type == 'c2'){
                    this.state.col2.push(item);
                }else if(item.type == 'c3'){
                    this.state.col3.push(item);
                }else{
                    this.state.col4.push(item);
                }
        })}
    
        return(
               <main className="bg-Foto h-auto position-relative curRojo ">
                    <div className="row  rowcol-2 m-0 p-0">
                        <div className="col-6-pr col-md-3 pr-0">
                            { this.state.col1.map((item) => {
                                                return(
                                                    <div className='card m-0 p-0 mt-3 border-0 ' >
                                                        <img src={"https://backend.zimbadigital.net/storage/fotos/" + item.urlImg} className='card-img-top border-0 ' alt=""/>
                                                    </div>
                                                        )
                                        })}
                        </div>
                
                        <div className="col-6-pr col-md-3 pr-0">
                           { this.state.col2.map((item) => {
                                                return(
                                                    <div className='card m-0 p-0 mt-3 border-0 ' >
                                                        <img src={"https://backend.zimbadigital.net/storage/fotos/" + item.urlImg} className='card-img-top border-0 ' alt=""/>
                                                    </div>
                                                        )
                                        })}
                        </div>
                
                        <div className="col-6-pr col-md-3 pr-0">
                           { this.state.col3.map((item) => {
                                                return(
                                                    <div className='card m-0 p-0 mt-3 border-0 ' >
                                                        <img src={"https://backend.zimbadigital.net/storage/fotos/" + item.urlImg} className='card-img-top border-0 ' alt=""/>
                                                    </div>
                                                        )
                                        })}
                        </div>
                        <div className="col-6-pr col-md-3 p-0">
                            { this.state.col4.map((item) => {
                                                return(
                                                    <div className='card m-0 p-0 mt-3 border-0 ' >
                                                        <img src={"https://backend.zimbadigital.net/storage/fotos/" + item.urlImg} className='card-img-top border-0 ' alt=""/>
                                                    </div>
                                                        )
                                        })}
                        </div>
                    </div>
                </main> 
                );
    }
}

export default Fotografias;
