/* eslint-disable jsx-a11y/role-supports-aria-props */
import React,{useState} from "react";
import { Button, Collapse } from "react-bootstrap";
import "./estilosEstBuscador.css";

function EstructuraBuscador(props){
    const [openState , setOpenState] = useState(true);
    const [girar ,setGirar]=useState('girarArriba');
    if(localStorage.getItem('Tema') == "bg-black"){
        var iconAbaj = require("../ImgEstilos/flechaAbajoBl.png");
    }else{
        var iconAbaj = require("../ImgEstilos/flechaAbajo.png");
    }
    
    
    var imgPrin = require("../EstructuraImg/" + props.ImgPrincipal);

    return (
      <div className="CajaBus">
        <div className="w-100 puntoBus "></div>

        <div className="d-flex align-items-center mt-1">
          <h2 className="ml-0 m-0 titlebusqueda">{props.TituloPadre}</h2>

          <img
            className={"FuenteBus ml-auto  " + girar}
            alt='bajarBoton'
            onClick={()=>{
              if (!openState) {
                setGirar('girarArriba');                
              }else{
                setGirar('girarAbajo');                
              }
              setOpenState(!openState)
            }}
            aria-expanded={openState}
            aria-controls="example-collapse-text"
            src={iconAbaj.default}
          />
       
        </div>
        
        
        <Collapse in={openState}>
          
        <div id="example-collapse-text" className=" flex-wrap align-items-center resBus2">
        <div className="punt puntoBus "></div>
          
          <div
          className="d-flex align-items-center mt-3   "
          style={{ minHeight: "40px" }}
        >
          <label
            className="simbolbus d-flex align-items-center mt-2 "
            style={{ height: "30px" }}
          >
            *
          </label>
          <label className="ml-0 ml-md-2" style={{ minHeight: "30px" }}>
          <span className="subtitlebus">{props.Titu}</span>
              
              {props.Rojo && <label className="mr-3 ml-2 fuenTex"><i class="fa fa-asterisk mt8" aria-hidden="true"></i></label>}
              <label className="secTxtImg "> {props.Rojo} </label>
              {props.Rojo2 && <label className="mr-3 ml-2 fuenTex"><i class="fa fa-asterisk mt8" aria-hidden="true"></i></label>}
            <label className="secTxtImg"> {props.Rojo2}</label>
            {props.hastLs &&
              props.hastLs.map((itemHash) => {
                return (
                  <>
                    <label className={"simbol2 " + itemHash.Color}># </label>
                    <label className={itemHash.Color}>{itemHash.Texto}</label>
                  </>
                );
              })}
          </label>
        </div>
          <div className='ml-3 resBus'>
          <img src={imgPrin.default} className="clSearch" alt="" />
            {props.lsSubGale.map((item) => {
              var urlSubImg = require("../EstructuraImg/" + item.urlImgSub);
              return (
                <img src={urlSubImg.default} className="clSearch" alt="" />
              );
            })}
            </div>
          </div>
        </Collapse>
      </div>
    );
  
}

export default EstructuraBuscador;
