import { render }
from "@testing-library/react";
import React, { Component }
from "react";
import { Image }
from "react-bootstrap";
import { withRouter }
from "react-router-dom";
import ModalImg from "../../ModalImg/modalImg";
import "./estiloVideo.css";
import ModalExpand from "./modalExpand/modalExpand";
import Menu from '../../Menu/menu'
import {  isChrome , isIOS } from 'react-device-detect';

class EstructuraVideo extends Component {
constructor(props) {
super(props);
        this.state = {
        modalS: '',
                fullScreen: false
        };
        }
componentDidMount() {
        this.refs.vidRef.controls = false;
        document.getElementsByTagName('figure')[0].addEventListener('fullscreenchange', (event) => {
                if (document.fullscreenElement) {
                        this.setState({ fullScreen: true })
                } else {
                        this.setState({ fullScreen: false })
                }
        })
        document.getElementsByTagName('figure')[1].addEventListener('fullscreenchange', (event) => {
                if (document.fullscreenElement) {
                        this.setState({ fullScreen: true })
                } else {
                        this.setState({ fullScreen: false })
                }
        })
        document.getElementsByTagName('figure')[2].addEventListener('fullscreenchange', (event) => {
                if (document.fullscreenElement) {
                        this.setState({ fullScreen: true })
                } else {
                        this.setState({ fullScreen: false })
                }
        })
        document.getElementsByTagName('figure')[3].addEventListener('fullscreenchange', (event) => {
                if (document.fullscreenElement) {
                        this.setState({ fullScreen: true })
                        console.log('full');
                } else {
                        this.setState({ fullScreen: false })
                        console.log('no full');
                }
        })
        document.getElementsByTagName('figure')[4].addEventListener('fullscreenchange', (event) => {
                if (document.fullscreenElement) {
                        this.setState({ fullScreen: true })
                        console.log('full');
                } else {
                        this.setState({ fullScreen: false })
                        console.log('no full');
                }
        })
}

        inciarVideo = () => {
        if(isIOS){
                document.querySelectorAll('video').forEach(b=>b.setAttribute("playsInline", ""));
                this.refs.vidRef.play();
                
        }
        else{
        this.refs.vidRef.play();
        }
        }
;
        pararVideo = () => {
                this.refs.vidRef.pause();
        };

        reiniciarVideo = () => {
        this.refs.vidRef.pause();
        this.refs.vidRef.currentTime = 0;
        this.refs.vidRef.play();
        }

        cerrarVideo = () => {
        window.location.reload()
        };

        expandVideo = () => {
                if(isIOS){
                        document.querySelectorAll('video').forEach(b=>b.removeAttribute('playsInline'));
                        this.refs.vidRef.play();
                        
                }
                else{
                        let vidUrl = "https://backend.zimbadigital.net/storage/Links/" + this.props.urlVideo;
                        let img1 = "https://backend.zimbadigital.net/storage/Links/" + this.props.urlImg;
                        if (localStorage.getItem('Tema') == 'bg-black') {
                                var imgPlay = require("../../ImgEstilos/playBl.png");
                                var imgPlay1 = require("../../ImgEstilos/play2Bl.png");
                                var imgPlay2 = require("../../ImgEstilos/puntosBl.png");
                                var imgPlay3 = require("../../ImgEstilos/cerrarBl.png");
                        } else {
                                var imgPlay = require("../../ImgEstilos/play.png");
                                var imgPlay1 = require("../../ImgEstilos/play2.png");
                                var imgPlay2 = require("../../ImgEstilos/puntos.png");
                                var imgPlay3 = require("../../ImgEstilos/cerrar.png");
                        }
                        var comp = (
                                <ModalExpand
                                urlV={vidUrl}
                                poster={img1}
                                clase='videoinFull'
                                imgPlay={imgPlay.default}
                                imgPlay1={imgPlay1.default}
                                imgPlay2={imgPlay2.default}
                                imgPlay3={imgPlay3.default}
                        />
                        );

                        this.setState({ fullScreen: true })

        let videoContainer = this.refs.videoContainer
        if (videoContainer.requestFullscreen) {
                videoContainer.requestFullscreen();
                }
        else if (videoContainer.mozRequestFullScreen) {

        videoContainer.mozRequestFullScreen();
        }
        else if (videoContainer.webkitRequestFullScreen) {
        videoContainer.webkitRequestFullScreen()
        }
        else if (videoContainer.msRequestFullscreen) {
        videoContainer.msRequestFullscreen();
        } else {
        }
                }
}

render(props) {
let vidUrl = "https://backend.zimbadigital.net/storage/Links/" + this.props.urlVideo;
        let img1 = "https://backend.zimbadigital.net/storage/Links/" + this.props.urlImg;
        if (localStorage.getItem('Tema') == 'bg-black') {
        var imgPlay = require("../../ImgEstilos/playBl.png");
        var imgPlay1 = require("../../ImgEstilos/play2Bl.png");
        var imgPlay2 = require("../../ImgEstilos/puntosBl.png");
        var imgPlay3 = require("../../ImgEstilos/cerrarBl.png");
        } else {
        var imgPlay = require("../../ImgEstilos/play.png");
        var imgPlay1 = require("../../ImgEstilos/play2.png");
        var imgPlay2 = require("../../ImgEstilos/puntos.png");
        var imgPlay3 = require("../../ImgEstilos/cerrar.png");
        }
var val = this.props.location.pathname == '/Videos' ? 'caBot' : ' ';
        if (document.getElementById('columna2')){
        document.getElementById('columna2').classList.remove('d-none');
        }
        var ultimo = "";
        if(this.props.ultimo == 'si'){
            ultimo = "d-none";
        }
        if(navigator.userAgent.indexOf('Safari') > -1){
            var simbol = "simbolVidOpera align-items-center";
        }else{
           var simbol = "simbolVid align-items-center";
        }
return (
        <div className={"widthPer pt-0 pb-1 pl-lg-2 pr-lg-2  ml-lg-auto menuVideo " + this.props.csN}>
    <div className='headerVideo'>
        <div className='titleHeader'>
            <label className={simbol}>
                *
            </label>
            <p className="">
                <span className="tituloVideo">
                    {this.props.Titulo}
                </span>
                <span className="simbolVid1 mb-0 asterisco2 ">
                    <i className="fa fa-asterisk" aria-hidden="true"></i>
                </span>
                <span className="VerdeVideo mb-0 goOther"> {this.props.Verde} </span>
                {this.props.Verde2 && (
                <>
                <span className="simbolVid2 ml-2 VerdeVideo mb-0">#</span>
                <span className="VerdeVideo mb-0 ">{this.props.Verde2}</span>
                </>
                )}
                {this.props.Rojo && (
                        <>
                <span className="simbolVid2 RojoVideo mb-0">#</span>
                <span className="RojoVideo mb-0 ">{this.props.Rojo}</span>
                </>
                )}
                {this.props.Rojo2 && (
                                <>
                <span className="simbolVid2 RojoVideo mb-0">#</span>
                <span className="RojoVideo mb-0 ">{this.props.Rojo2}</span>
                </>
                )}
                {this.props.Azul && (
                                        <>
                <span className="simbolVid2 AzulVideo mb-0">#</span>
                <span className="AzulVideo mb-0 ">{this.props.Azul}</span>
                </>
                )}
                {this.props.Azul1 && (
                                                <>
                <span className="simbolVid2 AzulVideo mb-0">#</span>
                <span className="AzulVideo mb-0 ">{this.props.Azul1}</span>
                </>
                )}
                {this.props.Azul2 && (
                                                        <>
                <span className="simbolVid2 AzulVideo mb-0">#</span>
                <span className="AzulVideo mb-0 ">{this.props.Azul2}</span>
                </>
                )}
            </p>
                                                        </div>
                                                        <div className='buttonsHeader'>
            <div className={"cajaBoton " + val}  >
                <button onClick={this.inciarVideo} className="transparentButton actVerde">
                    <img
                        src={imgPlay.default}
                        className="estiloPlay"
                        alt=""
                        />
                </button>

                <button onClick={this.pararVideo} className="transparentButton actVerde">
                    <img
                        src={imgPlay1.default}
                        className="estiloPlay1 ml-2"
                        alt=""
                        />
                </button>
                <button onClick={this.expandVideo} className="transparentButton actVerde">
                    <img
                        src={imgPlay2.default}
                        className="estiloPlay2 resPlay ml-2"
                        alt=""
                        />
                </button>

                <button onClick={this.reiniciarVideo} className="transparentButton actVerde" >
                    <img
                        src={imgPlay3.default}
                        className="estiloPlay2 ml-2"

                        alt=""
                        />
                </button>


            </div>
        </div>
        </div>
        <figure className={this.state.fullScreen ? "fullScreen" : "pl-lg-4 pr-lg-2" + " videoRes"} ref="videoContainer" >

        <div>
            {this.state.fullScreen && (<div className="controls">
                <button onClick={this.inciarVideo} className="transparentButton actVerde">
                    <img
                        src={require("../../ImgEstilos/playBl.png").default}
                        className="estiloPlay"
                        alt=""
                        />
                </button>

                <button onClick={this.pararVideo} className="transparentButton actVerde">
                    <img
                        src={imgPlay1 = require("../../ImgEstilos/play2Bl.png").default}
                        className="estiloPlay1"
                        alt=""
                        />
                </button>
                <button onClick={this.expandVideo} className="transparentButton actVerde">
                    <img
                        src={imgPlay2 = require("../../ImgEstilos/puntosBl.png").default}
                        className="estiloPlay2 resPlay"
                        alt=""
                        />
                </button>

                <button onClick={this.cerrarVideo} className="transparentButton actVerde" >
                    <img
                        src={imgPlay3 = require("../../ImgEstilos/cerrarBl.png").default}
                        className="estiloPlay2"
                        alt=""
                        />
                </button>
            </div>)}
            <video
                playsInline
                ref="vidRef"
                src={vidUrl}
                autoCorrect={true}
                poster={img1}
                className={this.props.editVideo}
                ></video>
        </div>
    </figure>
                                                        <div className={"ml-lg-4 mt-2 puntoVid  " + ultimo} ></div>
                                                        </div>
                                                        );
}
}

export default withRouter(EstructuraVideo);