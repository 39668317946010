import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./Menu/menu";
import { Col, Row } from "react-bootstrap";
import Escultura from "./Escultura/esculturas";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Dibujo from "./Dibujo/dibujo";
import Inicio from "./Inicio/inicio";
import Pintura from "./Pintura/pintura";
import Grabados from "./Grabados/grabados";
import Instalaciones from "./Instalaciones/instalaciones";
import Videos from "./Videos/videos";
import Fotografias from "./Fotografias/fotografias";
import Textos from "./Texto/texto";
import Buscador from "./Buscador/buscador";
import Archivo from "./Archivos/archivos";
import Todoloquepiensoyloqueno from "./Todoloquepiensoyloqueno/todoloquepiensoyloqueno";
import CV from "./CV/cv";
import Contacto from "./Contacto/contacto";
import { useEffect, useRef, useState } from "react";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('Tema'));
  const [language, setLanguaje] = useState(localStorage.getItem('Lenguaje'));
  const [statusLoq ,setLoq] =  useState('');
  const [curAct, setCurAct]  = useState('');
  let refFat = useRef('Papa');

  const changeTheme = () => {
      setTheme(localStorage.setItem("Tema", "bg-black"));
      window.location.reload(false);
  };

  const changeThemeWhite = () => {
      setTheme(localStorage.setItem("Tema", "bg-white"));
      window.location.reload(false);
  };
  
  const changelanguageEs = () => {
      setLanguaje(localStorage.setItem("Lenguaje", "english"));
      window.location.reload(false);
  };
   const changelanguage  = () => {
      setLanguaje(localStorage.setItem("Lenguaje", "espanish"));
      window.location.reload(false);
      
  };
  const  chStyleLoqPienso = (val)=>{
      setLoq(val);
  }


  const  chgCursor = (val)=>{
      setCurAct(val);
      localStorage.setItem('cursorActual',val);
  }
  if(localStorage.getItem('Lenguaje') == null){
      setLanguaje(localStorage.setItem("Lenguaje", "espanish"));
  }
  if (localStorage.getItem('Lenguaje') == 'espanish') {
                var escul = "Esculturas";
                var dibujo = "Dibujos";
                var pintura = "Pinturas";
                var grabado = "Grabados";
                var instala = "Instalaciones";
                var video = "Videos";
                var foto = "Fotografias";
                var textos = "Textos";
                var archivo = "Archivo";
                var stament = "Statement";
                var cv = "CV";
                var contact = "Contacto";
        } else {
                var escul = "Sculptures";
                var dibujo = "Drawings";
                var pintura = "Paintings";
                var grabado = "Engravings";
                var instala = "Installations";
                var video = "Videos";
                var foto = "Photographs";
                var textos = "Texts";
                var archivo = "Archive";
                var stament = "Statement";
                var cv = "CV";
                var contact = "Contact";
        }
        if (window.location.pathname == "/Statement") {
            var clases = " w-100 m-0 cursor bg-white " + curAct ;
        }else {
            var clases = " w-100 m-0 cursor " + theme + ' ' + curAct;
        }

  return (
          
      <Row   className={clases}>
        <div  className="  p-0 respMen col1" id="columna1">
          <Menu
            changeThemeChild={changeTheme}
            tema={theme}
            changeThemeWhiteChild={changeThemeWhite}
            changeLanguageChild={changelanguage}
            changeLanguageESChild={changelanguageEs}
            language={language}
            chStyleLoqPienso={chStyleLoqPienso}
            chgCursor={chgCursor}
          />
        </div>

        <div  className={"p-0 estiloCol col2" + statusLoq } id="columna2">
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route exact path={"/"+escul} component={Escultura} />
            <Route exact path={"/"+dibujo} component={Dibujo} />
            <Route exact path={"/"+pintura} component={Pintura} />
            <Route exact path={"/"+grabado} component={Grabados} />
            <Route exact path={"/"+instala} component={Instalaciones} />
            <Route exact path={"/"+video} component={Videos} />
            <Route exact path={"/"+foto} component={Fotografias} />
            <Route exact path={"/"+textos} component={Textos} />
            <Route exact path="/Buscador" component={Buscador} />
            <Route exact path={"/"+archivo} component={Archivo} />
            <Route exact path={"/"+stament} component={Todoloquepiensoyloqueno} />
            <Route exact path="/CV" component={CV} />
            <Route exact path={"/"+contact} component={Contacto} />
            <Route>
              <Redirect to="/"></Redirect>
            </Route>
          </Switch>
        </div>
      </Row>
  );
}

export default withRouter(App);
