import React, { Component }
from "react";
import { Button, Collapse, Image }
from "react-bootstrap";
import { withRouter }
from "react-router-dom";
import "./estructuraTexto.css";

class EstructuraTexto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            atras: false,
        };
    }

    onClickOpen = () => {
        this.setState({
            open: !this.state.open,
            atras: !this.state.atras,
        });
    }
    ;
            render(props) {
                if(document.getElementById('columna2')){
            document.getElementById('columna2').classList.remove('d-none');
        }
                    return (
                            <div className={"row widthPerTex1 padalling " + this.props.AlingText }>
                                <div className='col-12 d-flex marginb '>
                                    <span className="simbolText">*</span>
                                    <p class="ml-2 secTxtElement">
                                    <span className={this.props.color + ' fuenTex '}>{this.props.Titulo}</span>
                                    {this.props.Rojo !== null && (
                                        <>
                                        <span className= {"ml-1 mr-2 fuenTex " + this.props.color}>
                                            <i class="fa fa-asterisk" aria-hidden="true"></i>
                                        </span>
                                        <span className= {"text-lager " +this.props.color} > {this.props.Rojo}  </span>
                                        <span className= {"mras5 fuenTex " + this.props.color}>
                                            <i class="fa fa-asterisk" aria-hidden="true"></i>
                                        </span>
                                        </>
                                        )}
                                        {this.props.Rojo2 !== null && (
                                         <>
                                        <span className= {"ml-2 mr5 " + this.props.color}># </span>
                                        <span className= {this.props.color} > {this.props.Rojo2}</span>
                                        <span className={" ml-2 mr5 " + this.props.color}># </span>
                                        </>
                                        )}
                                        {this.props.Verde !== null && (
                                        <>
                                        <span className={this.props.color}>{this.props.Verde}</span>
                                        </>
                                        )}
                                        {(this.props.Azul !== null && this.props.Azul !== '') && (
                                        <>
                                        <span className={"ml-2 mr5 " + this.props.color}># </span>
                                        <span className={this.props.color}> {this.props.Azul}</span>
                                        </>
                                        )}
                                    </p>
                                </div>
                                {!this.props.cvTexto && (
                                  <div className={"col-12 d-flex marginb3 "} >
                                    <p className={"ml-4 mb-0 secTxtElement lineas " + this.props.color1}>
                                      {!this.state.atras && !this.props.expanVal && (
                                      <span
                                        onClick={this.onClickOpen}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={this.state.open}
                                        className={ this.props.flechaColor}
                                      >
                                              {!this.state.open && !this.props.Vinculo  && (
                                        <span
                                          dangerouslySetInnerHTML={{ __html: this.props.texto }}
                                        ></span>
                                      )}
                                        <i className="fas ml-2"> →</i>
                                      </span>
                                    )}

                                      {!this.state.open && this.props.Vinculo && <a href={this.props.texto}>{this.props.texto}</a> }

                                      <Collapse in={this.state.open} className="">
                                        <span id="example-collapse-text" className={this.props.color1}>
                                          {(this.props.ImgTexto1 !== null && this.props.ImgTexto1!==undefined) && (
                                            <>
                                              <Image
                                                className="ImgTexto1 d-block"
                                                src={this.props.ImgTexto1.default}
                                              />
                                            </>
                                          )}

                                          {this.state.atras && (
                                            <span
                                              onClick={this.onClickOpen}
                                              aria-controls="example-collapse-text"
                                              aria-expanded={this.state.open}
                                              className=""
                                            >
                                            <span
                                            dangerouslySetInnerHTML={{
                                              __html: this.props.texto + this.props.texto2,
                                            }}
                                          ></span>
                                              <i class="fas ml-2">←</i>
                                            </span>
                                          )}

                                          {(this.props.ImgTexto !== null &&  this.props.ImgTexto!==undefined )  && (
                                            <>
                                              <Image
                                                className="ImgTexto d-block"
                                                src={this.props.ImgTexto.default}
                                              />
                                            </>
                                          )}
                                        </span>
                                      </Collapse>
                                    </p>
                                  </div>
                                )}

                            {!this.props.TextoBG && <div className="col-12 punto puntotexto marginb2 ml-4 mr-2"></div>}
                        </div>
                        );
    }

    }

export default withRouter(EstructuraTexto);
