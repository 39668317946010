import React, { Component, createRef } from "react";
import EstructuraVideo from "./EstructuraVideo/estructuraVideo";

class Videos extends Component {
    constructor(props) {
        super(props);
        this.state = {lsVid: []};
        this.myRef = createRef();
    }

    componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getVideos.php";
        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({lsVid: resp});
                });
    }

    render() {

        return (
                <main ref={this.myRef} className=" p-0 m10top  curVerde Escultura">
                    {this.state.lsVid && this.state.lsVid.map((item, i) => {
                                    if (localStorage.getItem('Lenguaje') == 'espanish') {
                                        var Titulo = item.Titulo;
                                        var Verde2 = item.Verde2;
                                        var Rojo = item.Rojo;
                                        var Rojo2 = item.Rojo2;
                                        var Azul = item.Azul;
                                        var Azul1 = item.Azul1;
                                        var Azul2 = item.Azul2;
                                    } else {
                                        var Titulo = item.Titulo_en;
                                        var Verde2 = item.Verde2_en;
                                        var Rojo = item.Rojo_en;
                                        var Rojo2 = item.Rojo2_en;
                                        var Azul = item.Azul_en;
                                        var Azul1 = item.Azul1_en;
                                        var Azul2 = item.Azul2_en;
                                        
                                    }
                                    var val;
                                    var ultimo = 'no';
                                    if (i == 0) {
                                        val = 'pt-2'
                                    } else {
                                        val = ''
                                    }
                                    if (i == (this.state.lsVid.length - 1)) {
                                        ultimo = 'si'
                                    }
                                    return(
                                                <EstructuraVideo
                                                    csN={val}
                                                    refe={this.myRef}
                                                    editVideo={"editVideo"}
                                                    Titulo={Titulo}
                                                    Verde={item.Verde}
                                                    Verde2={Verde2}
                                                    Rojo={Rojo}
                                                    Rojo2={Rojo2}
                                                    Azul={Azul}
                                                    Azul1={Azul1}
                                                    Azul2={Azul2}
                                                    urlVideo={item.urlVideo}
                                                    urlImg={item.urlImg}
                                                    ultimo={ultimo}
                                                    />
                                                )
                                }) }
                
                
                </main>
                );
    }
}
export default Videos;