import React, { useState, useRef }
from "react";
import { Button, Collapse, Image, Modal, Nav }
from "react-bootstrap";
import EstructuraBuscador from "../EstructuraBuscador/estructuraBuscador";
import EstructuraImg from "../EstructuraImg/estructuraImg";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto";
import TextoBuscador from "../TextoBuscador/TextoBuscador";
import Videos from "../Videos/videos";
import "./busc.css";
function Buscador(props) {
const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const [data, setData] = useState([]);
        const [dataTxt, setDataTxt] = useState([]);
        const [valIn, setValIn] = useState("");
        const [vidOpen, setVidOpen] = useState(true);
        const [lsHash, setLsHash] = useState([]);
        const [lsHashTxt, setLsHashTxt] = useState([]);
        const [openSt, setOpenSt] = useState(true);
        const [gir, setGir] = useState("girarArriba");
        var iconB = require("../ImgEstilos/buscador.png");
        const urlIcon = require("../ImgEstilos/cerrar.png");
        let textInput = useRef(null);
        let textInputRespo = useRef(null);
        function handleClick() {

        }
const idInt = useState(0);
        const sendReq = async () => {
let ls = [];
        let lsTxt = [];
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/searchData.php?frase=";
            var errorbuscar = "¡UPS! NO SE ENCONTRARON RESULTADOS";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/searchData.php?lang=en&frase=";
            var errorbuscar = "UPS! NO RESULTS FOUND";
}
let req = await fetch(
        language + valIn
        );
        const convData = await req.json();
        setData(convData["Estructura"]);
        setDataTxt(convData["Texto"]);
        convData["Estructura"].map((item, i) => {
ls.push(item.Hash);
});
        if (convData["Texto"] != null) {
convData["Texto"].map((item, i) => {
lsTxt.push(item.Hash1);
        lsTxt.push(item.Hash2);
        lsTxt.push(item.Hash3);
});
}
if ((convData["Estructura"].length == 0)&&(convData["Texto"].length == 0)){
    document.getElementById("errorbusc").innerHTML = errorbuscar;
}
setLsHash(ls);
        setLsHashTxt(lsTxt);
};
        const handleCloseSearch = () => {
handleClose();
        setData([]);
        setValIn("");
};
        const pressSearch = (e) => {
if (e.charCode == 13) {
e.preventDefault();
        sendReq();
} else{
var ele = document.getElementById("campo");
        var tamano = ele.value.length + 1;
        ele.style.width = tamano * 45 + "px";
        ele.classList.add("mt-auto");
        ele.classList.add("mb-auto");
        }
};
        const pressErrase = (e) => {
var ele = document.getElementById("campo");
        var tamano = ele.value.length + 1;
        ele.style.width = tamano * 40 + "px";
        let eleBor = document.getElementById("campo");
        let cantVal = document.getElementById("campo").value;
        let eleWidth = document.getElementById("campo").offsetWidth;
        let eleHe = document.getElementById("campo").offsetHeight;
        if (e.keyCode == 32) {
eleBor.value = cantVal + " ";
}
if (e.keyCode == 8) {
if (eleWidth !== 0 && eleWidth > 0) {
eleBor.style.width = eleWidth + "px";
        eleBor.style.width = eleWidth - `0` + "px";
        eleBor.style.height = eleHe + "px";
        eleBor.style.height = eleHe - `0` + "px";
}
if (cantVal.length == 1) {
eleBor.style.width = "24px";
}
}
};
        if (localStorage.getItem('Tema') == "bg-black") {
var iconAbaj = require("../ImgEstilos/flechaAbajoBl.png");
} else {
var iconAbaj = require("../ImgEstilos/flechaAbajo.png");
}
if (localStorage.getItem('Lenguaje') == 'espanish') {
var buscar = "búsqueda";
} else {
var buscar = "search";
}
let tem;
        if (localStorage.getItem("Tema") == "bg-black") {
tem = "temaNegro";
}

const pressSearchRespo = (e) => {
if (e.charCode == 13) {
e.preventDefault();
        sendReq();
}
};
        const handleShowHash = () => {
setValIn(props.valorHas);
        sendReq();
        handleShow();
}

if(valIn == ""){
    data.length = 0;
    dataTxt.length = 0;
}

return (
        <>

{!props.hasTagSearch && (
                <a variant="primary" className="Buscador" onClick={handleShow}>
    <label className="estiloBusIco">[ </label> <label className="font20">{buscar}</label>{" "}
    <label className="estiloBusIco"> ]</label>
</a>
                )}

{props.hasTagSearch && <span onClick={handleShowHash}>{props.hasTagSearch}</span>
}


<Modal
    className=
    {
        "w-100 h-100 custCss " + localStorage.getItem("cursorActual")
    }

    show={show}

    onHide={handleClose}

    >
    {(data.length === 0)&&(dataTxt.length === 0) && (
                <div className={props.tema}>
        <div className="alt-search"><label className="estiloBusIco">[ </label> <label>{buscar}</label>{" "}
            <label className="estiloBusIco"> ]</label></div>
        <div className="alt-close ">
            <img
                src={urlIcon.default}
                className="cursor-pointer "
                onClick={handleClose}
                alt="Cursor "
                />
        </div>
        <div className="d-flex align-items-center alt-body justify-content-center  w-100  ">
            <textarea
                type="text"
                id="campo"
                role="textbox"
                onKeyPress={pressSearch}
                onKeyDown={pressErrase}
                onChange={(e) => {
        setValIn(e.target.value);
        }}
                className={"style-input  text-center " + props.tema}
                ref={textInput}
                autoComplete="off"
                ></textarea>
            <textarea
                className={"style-area text-center tex" + props.tema}
                id="campo2"
                role="textbox"
                onKeyPress={pressSearchRespo}
                ref={textInputRespo}
                autofocus="true"
                onChange={(e) => {
        let eleImg = document.getElementById("imgSearch");
                var ele = document.getElementById("campo2");
                ele.style.height = "200px";
                ele.style.height = 40 + ele.scrollHeight + "px";
                ele.style.width = "10px";
                ele.style.width = 10 + ele.scrollHeight + "px";
                eleImg.classList.add("bottomImg");
                ele.classList.add("mt-auto");
                ele.classList.add("mb-auto");
                setValIn(e.target.value);
        }}
                ></textarea>
            <img
                src={iconB.default}
                onClick={handleClick}
                className="img-size mr-4"
                alt="Icono buscar"
                id="imgSearch"
                />
            <div className="row errorbus">
                <div className="col-12">
                    <span className="rojo-flecha" id="errorbusc" ></span>
                </div>
            </div>
        </div>
        {props.tema == "bg-white" && (
                <Nav.Link
            className=" p-0  searchIcon iconCh "
            onClick={props.changeThemeChild}
            >
            <Image className="puntoN Fuente3 " src={props.botNeg.default} />
        </Nav.Link>
                )}
        {props.tema == "bg-black" && (
                <Nav.Link
            className=" p-0 searchIcon  iconCh color-white"
            onClick={props.changeThemeWhiteChild}
            >
            <Image className="puntoN Fuente3" src={props.botBla.default} />
        </Nav.Link>
                )}
    </div>
                )
    }


    {((data && data.length > 0) ||
                (dataTxt.length > 0 && valIn !== "")) && (
        <div className={"pl-3 pr-3 " + props.tema}>
        <div className="alt-search ml-0 ">[ {valIn} ]</div>
        <div className="alt-close respBus ">
            <img
                src={urlIcon.default}
                className=" cursor-pointer "
                onClick={handleCloseSearch}
                alt="Cursor "
                />
        </div>
        <div className="alt-close">
            <img
                src={urlIcon.default}
                className=" cursor-pointer "
                onClick={handleCloseSearch}
                alt=""
                />
        </div>
        <div className="mt-5">
            {data &&
        data.map((item, i) => {
        var lsSubImg = item.galeria[0].SubGaleria;
                var lsHastag = item.Hash;
                return (
                        <EstructuraBuscador
                key={i}
                IdBus={i}
                estado={true}
                TituloPadre={item.Tipo}
                ImgPrincipal={item.galeria[0].ImgPrincipal}
                lsSubGale={item.galeria[0].SubGaleria}
                Titulo={item.Titulo}
                Rojo={item.Descripcion}
                Rojo2={item.Medida}
                hastLs={lsHastag}
                Titu={item.Titulo}
                ></EstructuraBuscador>
                        );
        })}
            <div className="w-100 puntoBus"></div>
            {dataTxt &&
        dataTxt.map((item, i) => {
        return (
                <TextoBuscador key={i} idT={i} item={item}></TextoBuscador>
                );
        }
        )}

        </div>
    </div>
        )
    }

</Modal>
</>
);
}

export default Buscador;

/*
<div className='CajaBus'>
    <div className="w-100 puntoBus "></div>
    <div className='d-flex align-items-center'>
        <h2 className='ml-3'>Videos</h2>
        <img className='FuenteBus ml-auto '
             onClick=
        {() => setVidOpen(!vidOpen)}

             aria-controls="example-collapse-textV"
             aria-expanded=
             {vidOpen}

             src={iconAbaj.default}

             />
    </div>
    <div className="w-100 puntoBus "></div>

    <Collapse in=
              {vidOpen}
              >
        <div className='mr-auto vidBusc'>
            <Videos/>

        </div>
    </Collapse>

</div> */
