import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import "./modalImg.css";
const closeImg = require("../ImgEstilos/cerrar.png");
const bgIcon = require("../ImgEstilos/puntos.png");
const bgDer = require("../ImgEstilos/flechader.png");
const bgIzq = require("../ImgEstilos/flechaizq.png");
const bgAbja = require("../ImgEstilos/flechaAbajo.png");

function Controls(props) {
    console.log(props);

    const classButton = props.elementOrigin ? props.elementOrigin+'Mod' : "transparentButton";

    return (<div
        className={
            props.fullScreenButton ? "modalmenu-full" : "modalmenu" + " text-end"
        }
    >
        <button onClick={props.handleClose} className={classButton} >
            <img
                src={closeImg.default}
                className=" img_sz_menu"
                alt=""
            />
        </button>

        <button onClick={props.nextPhoto} className={classButton}>
            <img
                src={bgDer.default}
                className="img_sz_menu directionalArrows"
                alt=""
            />
        </button>



        <button onClick={props.previousPhoto} className={classButton}>
            <img
                src={bgIzq.default}
                className="img_sz_menu directionalArrows"
                alt=""
            />
        </button>

        {!props.fullScreenButton && ( <button className={classButton} onClick={props.handleFullScreenMode}><img
            src={bgIcon.default}
            className="img_sz_menu expandButton"
            alt=""
        /></button>)}
       

      


      

    </div>)
}
export default Controls