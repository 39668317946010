import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import "./modalImg.css";
import Controls from "./Controls"

function ModalImg(props) {
  const [show, setShow] = useState(false);
  const [bgBlanco, setBgBlanco] = useState("");
  const [urlImg, setUrlImg] = useState(props.comRender);
  const [urlImgModal, setUrlImgModal] = useState(props.comRender);
  const [position, setPosition] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);


  const handleShow = () => {
    setShow(true)
    setPosition(props.ind)
  }

  const handleClose = () => {
    setShow(false);
    resetModal()
    document.getElementById("columna2").style.display = 'block'
  };

  const resetModal = () => {
    setPosition(0)
    setFullScreen(false)

  }

  const stepPre = () => {
    if (position > 0) {
      setPosition(position - 1)
    }
  };
  const stepNext = () => {
    if (position < props.listaImg.length - 1) {
      setPosition(position + 1)
    }
  }

  const showModalAll = () => {
    setFullScreen(true)
    document.getElementById("columna2").style.display ='none'
  };

  console.log('MODAL=',props.elementOrigin);
  return (
    <>
      <div className="" style={{}} onClick={handleShow}>
        {urlImg}
      </div>
      <Modal
        animation={false}
        className={
          "p-0  d-flex justify-content-md-end  align-items-center md-Hei w-100 " +
          (fullScreen ? "" : "") +
          " " +
          localStorage.getItem("cursorActual")
        }
        show={show}
        onHide={handleClose}
        id="ModalCom"
      >
        {fullScreen ? (<>
          <div className="imgZoom ">
            <Image src={"https://backend.zimbadigital.net/storage/" + props.listaImg[position]}></Image>
            <Controls
              handleClose={handleClose}
              nextPhoto={stepNext}
              handleFullScreenMode={showModalAll}
              fullScreenButton={fullScreen}
              previousPhoto={stepPre}
              elementOrigin={props.elementOrigin}
            />
          </div>
        </>
        ) : (
          <>
          {console.log(props.listaImg[position])}
            <div className="imgstyle">
              <Image src={"https://backend.zimbadigital.net/storage/" + props.listaImg[position]}></Image>
              <Controls
              handleClose={handleClose}
              nextPhoto={stepNext}
              handleFullScreenMode={showModalAll}
              fullScreenButton={fullScreen}
              previousPhoto={stepPre}
              elementOrigin={props.elementOrigin}
            />
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default ModalImg;