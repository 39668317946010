import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';


class EstructuraArchivo extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const imgArch1 = require('../archivos/18.jpg');
        const imgArch2 = require('../archivos/b.jpg');
        const imgArch3 = require('../archivos/3.jpg');
        
        return ( 
            <main>
                <Row className='ml-5 mb-3 mt-3 mr-3 p-0'>
                    <Col className='m-0 p-0'>
                    
                    <img src={imgArch1.default} className='w-100 mt-2 d-block ml-auto' alt=""/>

                    </Col>

                    <Col className='m-0 p-0'>
                        <img src={imgArch1.default} className='w-100 mt-2 d-block ml-auto' alt=""/>
                    </Col>

                    <Col  className='m-0 p-0'>
                    <img src={imgArch1.default} className='w-100 mt-2 d-block ml-auto' alt=""/>

                    </Col>

                    <Col className='m-0 p-0'>
                    <img src={imgArch1.default} className='w-100 mt-2 d-block ml-auto' alt=""/>

                    </Col>
                </Row>
            </main>
         );
    }
}
 
export default EstructuraArchivo;