import React, { Component } from "react";
import EstructuraImg from "../EstructuraImg/estructuraImg";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto_1";
import "./texto.css";

class Texto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsTextos: [],
        };
    }

    componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getTextos1.php";
        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({
                        lsTextos: resp,
                    });
                });
    }

    render() {
         if (localStorage.getItem('Lenguaje') == 'espanish') {
                                        var link = "¿Qué voy hacer después?";
                                    } else {
                                        var link = "What am I going to do next?";
                                    }
        return (
                <main className="wt10 Escultura">
                    <div class="row widthPerTex1 padalling AlingText">
                        <div class="col-12 d-flex marginb ">
                            <span class="simbolText">*</span>
                            <p class="ml-2 secTxtElement">
                                <span class="colorNegro fuenTex ">[{link}]</span>
                                <a class="colorNegro fuenTex " href="https://emilianaistat.com/quevoyhacerdespues.pdf"><i class="fas ml-2"> →</i></a>
                                </p>
                        </div>
                        <div class="col-12 punto puntotexto marginb2 ml-4 mr-2">
                        </div>
                
                    </div>
                    {this.state.lsTextos && this.state.lsTextos.map((item, i) => {
                                    if (localStorage.getItem('Lenguaje') == 'espanish') {
                                        var Titulo = item.Titulo;
                                        var Resumen = item.Resumen;
                                        var Texto = item.Texto;
                                        var Hash1 = item.Hash1;
                                        var Hash2 = item.Hash2;
                                        var Hash3 = item.Hash3;
                                    } else {
                                        var Titulo = item.Titulo_en;
                                        var Resumen = item.Resumen_en;
                                        var Texto = item.Texto_en;
                                        var Hash1 = item.Hash1_en;
                                        var Hash2 = item.Hash2_en;
                                        var Hash3 = item.Hash3_en;

                                    }
                                    var imgUrl;
                                    var imgUrl2 = undefined;
                                    try {
                                        imgUrl = require(`${item.imgUrl}`);
                                    } catch (error) {
                                    }

                                    if (i === 2) {
                                        imgUrl2 = imgUrl;
                                        imgUrl = null;
                                    }
                                    return(
                                                <EstructuraTexto
                                                    flechaColor={item.flechaColor}
                                                    AlingText={"AlingText"}
                                                    Titulo={Titulo}
                                                    Titulo2={null}
                                                    Rojo={item.subTitulo}
                                                    Rojo2={Hash1}
                                                    Verde={Hash2}
                                                    Azul={Hash3}
                                                    SubTitulo={null}
                                                    mtH={"mtH"}
                                                    texto={
                                                            Resumen
                                                    }
                                                    texto2={Texto}
                                                    color={item.colorTxt}
                                                    color1={item.colorTxt}
                                                    ImgTexto={imgUrl}
                                                    ImgTexto1={imgUrl2}
                                                    />
                                                );


                                })}
                
                </main>
                );
    }
}

export default Texto;