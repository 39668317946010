import React, { Component } from 'react';
import EstructuraTexto from '../EstructuraTexto/estructuraTexto';
class Contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        }
        
        render() {
            if (localStorage.getItem('Lenguaje') == 'espanish') {
                var saludo = "[Hola :D]";
                var colabora = "Colaboraciones";
                var project = "Proyectos";
                var exposicio = "Exposiciones";
                var consulta = "Consultas";
                var mail = "Mail";
                var tel = "Telefono";
                var location = "Locación";
            } else {
                var saludo = "[Hi :D]";
                var colabora = "Collaborations";
                var project = "Projects";
                var exposicio = "Exhibitions";
                var consulta = "Enquiries";
                var mail = "Email";
                var tel = "Phone";
                var location = "Location";
            }
        return (<main className="curRojo widthPer Escultura">
            <div className="widthPerTex movContacto textobajo1">
                <div className="align-items-center tt d-none d-md-flex">
                    <h2 className="colorNegro mrH  margen ">{saludo}</h2>
                </div>
                <div className="punto mb-2 d-none d-md-flex"></div>
                <h2 className="colorNegro margen "> {colabora}
                    <span className="ml-1 mr-2 fuenTex">
                        <i class="fa fa-asterisk" aria-hidden="true"></i>
                    </span> {project}
                    <span className="ml-1 mr-2 fuenTex ">
                        <i class="fa fa-asterisk" aria-hidden="true"></i>
                    </span> {exposicio} 
                    <span className=" ml-1 mr-2 fuenTex ">
                        <i class="fa fa-asterisk" aria-hidden="true"></i>
                    </span> {consulta}
                </h2>
                <div className="vectors" ></div>
            </div>
            <div class="widthPerTex movContacto textobajo1">
                <div class="punto mb-2 "></div>
                <h2 class="secTxt margen">→ {mail} </h2>
                <div class="align-items-center">
                    <h2 class="    secTxt">
                        <a href="mailto:miminaistat@gmail.com">miminaistat@gmail.com</a>
                        <div id="example-collapse-text" class="secTxt collapse">
                            <div>miminaistat@gmail.comnull</div>        
                        </div>
                    </h2>
                </div>
            </div>
            <div class="widthPerTex movContacto textobajo1">
                <div class="punto mb-2 undefined"></div>
                <h2 class="terTxtFirst  margen">→ {tel} </h2>
                <div class="align-items-center   undefined">
                    <h2 class="    terTxtFirst">
                        <div>+54 9 11 5661-9861</div>
                        <div id="example-collapse-text" class="terTxtFirst collapse">
                            <div>+54 9 11 5661-9861null</div>
        
                        </div>
                    </h2>
                </div>
            </div>
            <div className="widthPerTex movContacto textobajo1">
                <div className=" punto mb-2"></div>
                <h2 className="textazul margen">→ Instagram </h2>
                <div className="align-items-center">
                    <h2 className="textazul">
                        <a className="textazul" href="https://www.instagram.com/this.is.propaganda" target="_blank" >@this.is.propaganda</a><br/>
                        <a className="textazul" href="https://www.instagram.com/miminaistat" target="_blank" >@miminaistat</a>
                    </h2>
                </div>
            </div>
        
            <div class="widthPerTex undefined undefined undefined movContacto textobajo1">
                <div class="punto mb-2 undefined"></div>
                <h2 class="colorNegro margen">→ {location} </h2>
                <div class="align-items-center   undefined">
                    <h2 class="    colorNegro">
                        <div>CABA, Buenos Aires
                            <br/> Argentina</div>
                        <div id="example-collapse-text" class="colorNegro collapse"><div>CABA, Buenos Aires
                                <br/> Argentinanull</div>
                        </div>
                    </h2>
                </div>
            </div>
        </main>);

    }
}

export default Contacto; 