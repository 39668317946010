import React, { Component, createRef } from 'react';
import EstructuraImg from '../EstructuraImg/estructuraImg';
import ModalExpand from '../Videos/EstructuraVideo/modalExpand/modalExpand';
import {  isChrome, isIOS } from 'react-device-detect';
import './insta.css'

        class Instalaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsInst: [],
            modalS: ''
        }
        this.myRef = createRef();
    }
    async componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getInstalaciones.php";
        let url = language;
        fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({lsInst: res});
                });
    }

    inciarVideo = () => {
        if (isIOS) {
            document.querySelectorAll('video').forEach(b => b.setAttribute("playsInline", ""));
            this.refs.vidRef.play();

        } else {
            this.refs.vidRef.play();
        }
    }
    ;
            pararVideo = () => {
        this.refs.vidRef.pause();
    }
    ;
            reiniciarVideo = () => {
        this.refs.vidRef.pause();
        this.refs.vidRef.currentTime = 0;
        this.refs.vidRef.play();
    }

    expandVideo = () => {
        if (isIOS) {
            document.querySelectorAll('video').forEach(b => b.removeAttribute('playsInline'));
            this.refs.vidRef.play();

        } else {
            var imgPlay = require("../ImgEstilos/playBl.png");
            var imgPlay1 = require("../ImgEstilos/play2Bl.png");
            var imgPlay2 = require("../ImgEstilos/puntosBl.png");
            var imgPlay3 = require("../ImgEstilos/cerrarBl.png");

            var vidUrl = require('../EstructuraImg/Instalacion/Todxs.mp4');


            var comp = (
                    <ModalExpand
                        urlV={vidUrl.default}
                        clase='videoinFull'
                        imgPlay={imgPlay.default}
                        imgPlay1={imgPlay1.default}
                        imgPlay2={imgPlay2.default}
                        imgPlay3={imgPlay3.default}
                        />
                    );
            this.setState({modalS: comp})
        }
        ;
    }

    render() {
        return (<main  ref={this.myRef} className=' m-0 p-0  curNegro Escultura m10top' >            
            { this.state.lsInst && this.state.lsInst.map((item, i) => {
                            if (localStorage.getItem('Lenguaje') == 'espanish') {
                                var Titulo = item.Titulo;
                                var Descripcion = item.Descripcion;
                                var textoInfo = item.TextoInfo;
                            } else {
                                var Titulo = item.Titulo_en;
                                var Descripcion = item.Descripcion_en;
                                var textoInfo = item.TextoInfo_en;
                            }
                            var lsSubImg = item.galeria[0].SubGaleria;
                            var lsHastag = item.Hash;
                            var limite = null;
                            var estilosTex = null;
                            var ocu = false;
                            var compVideo;
                            var comController;
                            var widthSub;
                            let intInfo = '';
                            let otherInfo = '';
                            var puntoFinal = null;
                            var FlechaDerecha = null;

                            if (i == 0) {
                                ocu = true;
                                limite = 4;
                                widthSub = 'subImg';

                            }


                            if (i == 1) {
                                ocu = true;
                                limite = 3;
                                widthSub = 'subImg';
                            }
                            if (i == 2) {
                                ocu = true;
                                limite = 3;
                                widthSub = 'subImg';

                                if (textoInfo !== undefined && textoInfo !== null) {
                                    for (let j = 0; j < textoInfo.length; j++) {
                                        if (j > 200) {
                                            otherInfo += textoInfo.charAt(j);
                                        } else {
                                            intInfo += textoInfo.charAt(j);
                                        }
                                    }
                                }
                            }

                            if (i === 3) {
                                ocu = true;
                                limite = 3;
                                widthSub = 'subImg';
                                //puntoFinal=true;
                            }

                            if (i === 4) {
                                ocu = true;
                                puntoFinal = true;
                                //widthSub='subImg';
                                if (textoInfo !== undefined && textoInfo !== null) {
                                    for (let j = 0; j < textoInfo.length; j++) {
                                        if (j > 85) {
                                            otherInfo += textoInfo.charAt(j);
                                        } else {
                                            intInfo += textoInfo.charAt(j);
                                        }
                                    }
                                    FlechaDerecha = true;
                                }

                                let  url = require('../EstructuraImg/Instalacion/Todxs.mp4');
                                if (localStorage.getItem('Tema') == 'bg-black') {
                                    var imgPlay = require("../ImgEstilos/playBl.png");
                                    var imgPlay1 = require("../ImgEstilos/play2Bl.png");
                                    var imgPlay2 = require("../ImgEstilos/puntosBl.png");
                                    var imgPlay3 = require("../ImgEstilos/cerrarBl.png");
                                } else {
                                    var imgPlay = require("../ImgEstilos/play.png");
                                    var imgPlay1 = require("../ImgEstilos/play2.png");
                                    var imgPlay2 = require("../ImgEstilos/puntos.png");
                                    var imgPlay3 = require("../ImgEstilos/cerrar.png");
                                }
                                var vidImg = require('../EstructuraImg/Instalacion/cover.jpg');

                                comController = (
                                    <div className="cajaBoton1  float-right col-4">
                                        <img 
                                            src={imgPlay.default}
                                            className='mr-1'
                                            onClick={this.inciarVideo}
                                            alt=""
                                            />
                                        <img
                                            src={imgPlay1.default}
                                            className='ml-2 mr-1'
                                            onClick={this.pararVideo}
                                            alt=""
                                            />
                                        <img
                                            src={imgPlay2.default}
                                            onClick={this.expandVideo}
                                            className='ml-2 mr-1'
                                
                                            alt=""
                                            />
                                        <img
                                            src={imgPlay3.default}
                                            style={{height: '19px'}}
                                            className='ml-2'
                                
                                            onClick={this.reiniciarVideo}
                                            alt=""
                                            />
                                    </div>

                                        );

                                compVideo = (
                                    <div class="mt-3 tamano" >
                                        <div className="">
                                            <img
                                                src={vidImg.default}
                                                id=""
                                                alt=""
                                                className="w-100"
                                                />
                                        </div>
                                
                                        <video
                                            playsInline
                                            ref="vidRef"
                                            src={url.default}
                                            poster={vidImg.default}
                                            className='w-100 videoIn disnone'
                                            ></video>
                                        {this.state.modalS}
                                    </div>
                                        );
                            }

                            return (
                                <EstructuraImg
                                    refe={this.myRef}
                                    clImg={''}
                                    Titulo={Titulo}
                                    Rojo={Descripcion}
                                    Rojo2={item.Medida}
                                    Verde={"conflicto "}
                                    Azul={"fantasia"}
                                    Lim = {limite}
                                    widthSub={widthSub}
                                    CompVideo={compVideo}
                                    comController={comController}
                                    EstilosTex={estilosTex}
                                    showMore={ocu}
                                    WidthImg={item.galeria[0].WidthImg}
                                    hideFotos={true}
                                    ImgPrincipal={item.galeria[0].ImgPrincipal}
                                    ResumenTexto={intInfo}
                                    otherInfo={otherInfo}
                                    TextoInfo={textoInfo}
                                    listGale={lsSubImg}
                                    hastLs={lsHastag}
                                    puntoFinal={puntoFinal}
                                    FlechaDerecha={FlechaDerecha}
                                    elementOrigin={'instalaciones'}
                                    />
                                        );
                        })}
        </main>);
    }
}

export default Instalaciones;