import React, { Component, createRef } from "react";

import EstructuraImg from "../EstructuraImg/estructuraImg";
import './dibujo.css'
        class Dibujo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsDib: [],
        };
        this.myRef = createRef();

    }

    async componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getDibujos.php";
        let url = language;
        fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({lsDib: res});
                });
    }

    render() {

        return (
                <main  ref={this.myRef} className=" curNegro Escultura m10top" >
                    { this.state.lsDib && this.state.lsDib.map((item, i) => {
                                    if (localStorage.getItem('Lenguaje') == 'espanish') {
                                        var Titulo = item.Titulo;
                                        var Descripcion = item.Descripcion;
                                    } else {
                                        var Titulo = item.Titulo_en;
                                        var Descripcion = item.Descripcion_en;
                                    }
                                    var lsSubImg = item.galeria[0].SubGaleria;
                                    var lsHastag = item.Hash;
                                    var limite = null;
                                    var estilosTex = null;
                                    var ocu = false;
                                    var widthSub;
                                    var brAct;
                                    var mediaQ = null
                                    var mediaQ2 = null;
                                    if (i == 1) {
                                        ocu = true;
                                        mediaQ = 'mediaQ';
                                        mediaQ2 = 'mediaQ2';
                                    }
                                    if (i == 2) {
                                        ocu = true
                                        limite = 6;

                                    }

                                    if (i == 3) {
                                        ocu = true;
                                        limite = 3;
                                        widthSub = 'subImg';
                                    }

                                    if (i == 4) {
                                        estilosTex = 'labelStlye';
                                        brAct = true;

                                    }
                                    if (this.state.lsDib.length - 1 == i) {
                                        return (
                                                    <EstructuraImg
                                                        className='curNegro'
                                                        refe={this.myRef}
                                                        Titulo={Titulo}
                                                        Lim = {limite}
                                                        widthSub={widthSub}
                                                        brAct={brAct}
                                                        EstilosTex={estilosTex}
                                                        Rojo={Descripcion}
                                                        Rojo2={item.Medida}
                                                        Verde={"conflicto "}
                                                        Azul={"fantasia"}
                                                        showMore={ocu}
                                                        mediaQ={mediaQ}
                                                        mediaQ2={mediaQ2}
                                                        hideFotos={true}
                                                        WidthImg={item.galeria[0].WidthImg}
                                                        ImgPrincipal={item.galeria[0].ImgPrincipal}
                                                        listGale={lsSubImg}
                                                        hastLs={lsHastag}
                                                        puntoFinal={true}
                                                        elementOrigin={'instalaciones'}
                                                
                                                        />
                                                    );

                                        } else {
                                            return (
                                                    <EstructuraImg
                                                        className='curNegro'
                                                        refe={this.myRef}
                                                        Titulo={Titulo}
                                                        Lim = {limite}
                                                        widthSub={widthSub}
                                                        brAct={brAct}
                                                        EstilosTex={estilosTex}
                                                        Rojo={Descripcion}
                                                        Rojo2={item.Medida}
                                                        Verde={"conflicto "}
                                                        Azul={"fantasia"}
                                                        showMore={ocu}
                                                        mediaQ={mediaQ}
                                                        mediaQ2={mediaQ2}
                                                        hideFotos={true}
                                                        WidthImg={item.galeria[0].WidthImg}
                                                        ImgPrincipal={item.galeria[0].ImgPrincipal}
                                                        listGale={lsSubImg}
                                                        hastLs={lsHastag}
                                                        elementOrigin={'instalaciones'}
                                                        />
                                                    );
                                        }
                                })}
                </main>
                );
    }
}

export default Dibujo;
