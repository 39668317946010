import React, { Component, createRef }
from "react";
import { Image, Collapse }
from "react-bootstrap";
import { withRouter }
from "react-router-dom";
import Buscador from "../Buscador/buscador";
import EstructuraBuscador from "../EstructuraBuscador/estructuraBuscador";
import ModalImg from "../ModalImg/modalImg";
import "./estructuraImg.css";

class EstructuraImg extends Component {
constructor(props) {
super(props);
        this.state = {
        urlImg: "",
                comI: [],
                showImg: false,
                notShow: false,
                compTexto: "",
                open: false,
                atras: false,
        };
        this.refCh = createRef();
        }
onClickOpen = () => {
this.setState({
showImg: !this.state.showImg,
        notShow: !this.state.notShow,
        });
        };
        chImgPrev = (prevImg) => {
if (prevImg !== null && prevImg !== undefined) {
prevImg -= 1;
        return prevImg;
        }
};
        chImgNext = (prevImg) => {
if (prevImg !== null && prevImg !== undefined) {
prevImg += 1;
        return prevImg;
        }
};
        changeShow = () => {
this.setState({
open: !this.state.open,
        atras: !this.state.atras,
        });
        };
        render(props) {
var imgPrin = "https://backend.zimbadigital.net/storage/" + this.props.ImgPrincipal;
        var listaImg = [];
        listaImg.push(this.props.ImgPrincipal);
        this.props.listGale.forEach((element) => {
        listaImg.push(element.urlImgSub);
        });
        var datos = [];
        if (this.props.CompVideo !== undefined) {
var col = "col-8-per";
        } else {
var col = "col-12";
        }
if (document.getElementById('columna2')){
document.getElementById('columna2').classList.remove('d-none');
        //console.log("entre");
        }

if (localStorage.getItem('Lenguaje') == 'espanish') {
var vermas = 'ver más';
        var vermenos = 'ver menos';
        } else {
var vermas = 'see more';
        var vermenos = 'see less';
        }

//console.log('EstructuraImg=',this.props.elementOrigin);

if (this.props.clImg !== null) {
return (
        <div
    className={"widthPer pl-2 pr-2 ml-auto " + this.props.classSearch}
    >
    <div className={"d-flex  textEst " + col} style={{ minHeight: "20px" }}>
        <label
            className={
        "simbol d-flex align-items-center  " +
        this.props.EstilosTex +
        "mt-0"
            }
            style={{ maxHeight: "24px" }}
            >
            *
        </label>

        <label
            htmlFor=""
            className=" ml-2 labelTexto "
            style={{ minHeight: "10px" }}
            >
            {" "}
            {this.props.Titulo}
            {this.props.Rojo && <label className="asterisco1 fuenTex"><i class="fa fa-asterisk mt8" aria-hidden="true"></i></label>}
            <span className="secTxtImg "> {this.props.Rojo} </span>
            {this.props.Rojo2 && <label className="asterisco2 fuenTex"><i class="fa fa-asterisk mt8" aria-hidden="true"></i></label>}
            <label className="secTxtImg mr-1 "> {this.props.Rojo2}</label>
            {this.props.hastLs &&
        this.props.hastLs.map((itemHash) => {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
                var texto = itemHash.Texto;
        } else {
         var texto = itemHash.Texto_en;
                }
        var hasTag = (
                <>
            <label className={"simbol2 " + itemHash.Color}># </label>
            <label
                className={itemHash.Color}

                >
                {texto}
            </label>
            </>
            );
            return <Buscador hasTagSearch={hasTag} valorHas={itemHash.Texto}></Buscador>;
            })}
        </label>
                </div>
        {this.props.comController}

        {this.props.TextoInfo && this.props.ResumenTexto === "" && (
                <div className="ml-md-4">
        <span
            dangerouslySetInnerHTML={{ __html: this.props.TextoInfo }}
            className="TextoInfo"
            ></span>
    </div>
                )}

        {this.props.ResumenTexto && (
                <div className="ml-4 textocom">
        {!this.state.atras && !this.props.expanVal && (
                        <span
            onClick={this.changeShow}
            aria-controls="example-collapse-text"
            aria-expanded={this.state.open}
            className={"TextoInfo ml-4" + this.props.flechaColor}
            >
            <span
                dangerouslySetInnerHTML={{ __html: this.props.ResumenTexto }}
                className="TextoInfo"
                ></span>
            <i className="fas">→</i>
        </span>
                        )}

        <Collapse in={this.state.open} className="">
            <div id="example-collapse-text">
                {this.state.atras && (
                <span
                    onClick={this.changeShow}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open}
                    className=""
                    >
                    <span
                        dangerouslySetInnerHTML={{ __html: this.props.ResumenTexto }}
                        className="TextoInfo"
                        ></span>
                    <span
                        dangerouslySetInnerHTML={{ __html: this.props.otherInfo }}
                        className="mt-0 TextoInfo"
                        ></span>
                    <i class="fas ">←</i>
                </span>
                )}
            </div>
        </Collapse>
    </div>
                )}

        <div className="pl-4 pr-2 m-0 respon  ">
        {this.props.CompVideo !== undefined && (
                <div>{this.props.CompVideo}</div>
                )}
        {this.props.CompVideo == undefined && (
                <ModalImg
            imagenUrl={this.props.ImgPrincipal}
            refs={this.myRef}
            indi={listaImg.length}
            ind={0}
            disShow={false}
            listaImg={listaImg}
            chImg={this.changImgMod}
            elementOrigin={this.props.elementOrigin}
            comRender={
                <Image
            className={"rsImg " + this.props.WidthImg}
            src={imgPrin}
            />
        }
        compTexto={
                <div
            className="d-flex align-items-center mt-1  "
            style={{ minHeight: "40px" }}
            >
            <label
                className="simbol d-flex align-items-center "
                style={{ height: "30px", paddingTop: "0.2%" }}
                >
                *
            </label>

            <label
                htmlFor=""
                className=" ml-2 d-flex align-items-center"
                style={{ minHeight: "30px" }}
                >
                {" "}
                <label htmlFor="" className="labelTexto">
                    {this.props.Titulo}
                </label>
                {this.props.Rojo && (
                <label className="simbol2  ">
                    <i class="fa fa-asterisk" aria-hidden="true"></i>
                </label>
                )}
                <span className="secTxtImg "> {this.props.Rojo} </span>
                {this.props.Rojo2 && (
                <label className="simbol2 ">
                    <i class="fa fa-asterisk" aria-hidden="true"></i>
                </label>
                )}
                <label className="secTxtImg"> {this.props.Rojo2}</label>
                {this.props.hastLs &&
                this.props.hastLs.map((itemHash) => {
                return (
                        <>
                <label className={"simbol2 " + itemHash.Color}>
                    {" "}
                    #{" "}
                </label>
                <label className={itemHash.Color}>
                    {itemHash.Texto}
                </label>
                </>
                );
                })}
            </label>
                        </div>
                }
                />
                        )}

        <div className="d-flex flex-wrap  w-100 mt-3  position-relative h-auto ">
            {this.props.listGale &&
                        this.props.listGale.map((itSub, i) => {
                        var urla = "https://backend.zimbadigital.net/storage/" + itSub.urlImgSub;
                                console.log(itSub.urlImgSub + '*');
                                if (this.props.hideFotos == undefined && i < 6) {
                        return (
                                <ModalImg
                key={i}
                ind={i + 1}
                indi={listaImg.length}
                listaImg={listaImg}
                lsG={this.props.listGale}
                imagenUrl={itSub.urlImgSub}
                refs={this.myRef}
                elementOrigin={this.props.elementOrigin}
                compTexto={
                                <div
                className="d-flex align-items-center mt-1  "
                style={{ minHeight: "40px" }}
                >
                <label
                    className={
                                "simbol d-flex align-items-center " +
                                this.props.EstilosTex
                    }
                    style={{ height: "30px", paddingTop: "0.2%" }}
                    >
                    *
                </label>

                <label
                    htmlFor=""
                    className="ml-2 d-flex align-items-center"
                    style={{ minHeight: "30px" }}
                    >
                    {" "}
                    <label htmlFor="" className="labelTexto">
                        {this.props.Titulo}
                    </label>
                    {this.props.Rojo && (
                                <label className="simbol2  "><i class="fa fa-asterisk" aria-hidden="true"></i></label>
                                )}
                    <span className="secTxtImg ">
                        {" "}
                        {this.props.Rojo}{" "}
                    </span>
                    {this.props.Rojo2 && (
                                <label className="simbol2 "><i class="fa fa-asterisk" aria-hidden="true"></i></label>
                                )}
                    <label className="secTxtImg">
                        {" "}
                        {this.props.Rojo2}
                    </label>
                    {this.props.hastLs &&
                                this.props.hastLs.map((itemHash) => {
                                return (
                                        <>
                    <label
                        className={"simbol2 " + itemHash.Color}
                        >
                        {" "}
                        #{" "}
                    </label>
                    <label className={itemHash.Color}>
                        {itemHash.Texto}
                    </label>
                    </>
                    );
                    })}
                </label>
                                        </div>
                                }
                                chImgNext = {this.chImgNext}
                                chImgPrev = {this.chImgPrev}
                                comRender = {
                                <Image
            className="cajaImg mr-3 mb-3 "
            src={urla}
            style={{ height: "110px" }}
            />
                                }
                                > </ModalImg>
                                        );
        }

        if (this.props.hideFotos && this.props.Lim == null && i < 7) {
                                        return (
                                                <ModalImg
            key={i}
            ind={i + 1}
            indi={listaImg.length}
            listaImg={listaImg}
            lsG={this.props.listGale}
            imagenUrl={itSub.urlImgSub}
            refs={this.myRef}
            chImgNext={this.chImgNext}
            chImgPrev={this.chImgPrev}
            elementOrigin={this.props.elementOrigin}
            comRender={
                                                <Image
            className={
                                                "cajaImg mr-3 mb-3 " +
                                                this.props.clImg +
                                                " " +
                                                this.props.mediaQ
            }
            src={urla}
            style={{ height: "110px" }}
            />
        }
        ></ModalImg>
                                                );
        }

        if (this.props.hideFotos && this.props.Lim == 6 && i < 6) {
                                        return (
                                                <ModalImg
            key={i}
            ind={i + 1}
            indi={listaImg.length}
            listaImg={listaImg}
            lsG={this.props.listGale}
            imagenUrl={itSub.urlImgSub}
            refs={this.myRef}
            chImgNext={this.chImgNext}
            chImgPrev={this.chImgPrev}
            elementOrigin={this.props.elementOrigin}
            comRender={
                                                <Image
            className={"cajaImg mr-3 mb-3 " + this.props.clImg}
            src={urla}
            style={{ height: "110px" }}
            />
        }
        ></ModalImg>
                                                );
        }

        if (this.props.hideFotos && this.props.Lim == 4 && i < 4) {
                                        return (
                                                <ModalImg
            key={i}
            ind={i + 1}
            indi={listaImg.length}
            listaImg={listaImg}
            lsG={this.props.listGale}
            imagenUrl={itSub.urlImgSub}
            refs={this.myRef}
            chImgNext={this.chImgNext}
            chImgPrev={this.chImgPrev}
            elementOrigin={this.props.elementOrigin}
            comRender={
                                                <Image
            className={"cajaImg mr-3 mb-3 " + this.props.clImg}
            src={urla}
            style={{ height: "110px" }}
            />
        }
        ></ModalImg>
                                                );
        }

        if (this.props.hideFotos && this.props.Lim == 3 && i < 3) {
                                        return (
                                                <ModalImg
            key={i}
            ind={i + 1}
            indi={listaImg.length}
            listaImg={listaImg}
            lsG={this.props.listGale}
            imagenUrl={itSub.urlImgSub}
            refs={this.myRef}
            chImgNext={this.chImgNext}
            chImgPrev={this.chImgPrev}
            elementOrigin={this.props.elementOrigin}
            comRender={
                                                <Image
            className={"cajaImg mr-3 mb-3 " + this.props.clImg}
            src={urla}
            style={{ height: "110px" }}
            />
        }
        ></ModalImg>
                                                );
        }

        if (this.props.hideFotos && i >= 3) {
                                        datos.push(
                                                <ModalImg
            key={i}
            ind={i + 1}
            indi={listaImg.length}
            listaImg={listaImg}
            lsG={this.props.listGale}
            imagenUrl={itSub.urlImgSub}
            refs={this.myRef}
            chImgNext={this.chImgNext}
            chImgPrev={this.chImgPrev}
            elementOrigin={this.props.elementOrigin}
            comRender={
                                                <Image
            className={
                                                " cajaImg  mr-3 mb-3   " +
                                                this.props.widthSub +
                                                " " +
                                                this.props.mediaQ2
            }
            src={urla}
            style={{ height: "108px" }}
            />
        }
        ></ModalImg>
                                                );
        }
        })}
    </div>
    {!this.state.notShow && this.props.showMore && (
                                                <label
        onClick={this.onClickOpen}
        aria-controls="example-collapse-text"
        aria-expanded={this.state.showImg}
        className={" mb-2 fuenteVerMas " + this.props.flechaColor}
        >
        {vermas} <i className="fas">→</i>
    </label>
                                                )}
    <Collapse in={this.state.showImg} className="">
        <div>
            {this.props.hideFotos && !this.props.SubLim && (
                                        <div className="d-flex flex-wrap">
                {datos.map((item) => {
                                        return item;
                                        })}
            </div>
                                        )}
            {this.props.hideFotos && this.props.SubLim && (
                                        <div className="d-flex flex-wrap  w-100  position-relative h-auto ">
                {datos.map((item) => {
                                        return <div className="  ">{item}</div>;
                                        })}
            </div>
                                        )}
        </div>
    </Collapse>
    {this.state.notShow && (
                                        <label
        onClick={this.onClickOpen}
        aria-controls="example-collapse-text"
        aria-expanded={this.state.open}
        className="mb-2  fuenteVerMas"
        >
        {vermenos} <i class="fas ">←</i>
    </label>
                                        )}
    {this.props.puntoFinal && <div></div>}
    {!this.props.puntoFinal && <div className="w-100 punto">
    </div>}
</div>
</div>
);
}
}
}

export default withRouter(EstructuraImg);
