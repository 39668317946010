import React, { createRef, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../estiloVideo.css';
import Menu from "../../../Menu/menu";

function ModalExpand(props) {
  const [show, setShow] = useState(true);
  var refC = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inciarVideo = () => {
    refC.current.play();
  };

  const pararVideo = () => {
    refC.current.pause();
  };

  const reiniciarVideo = () => {
    refC.current.pause();
    refC.current.currentTime = 0;
    refC.current.play();
  };

  const handleReset = () => {
    window.location.reload();
  }

  return (<div>
    {show ? (<div className='videoCointainerFullScreen'>
      <div>
        <div className='extendVideo'>
          <img src={props.imgPlay} onClick={inciarVideo} alt="" />
          <img src={props.imgPlay1} onClick={pararVideo} alt="" />
          <img src={props.imgPlay2} onClick={reiniciarVideo} alt="" />
          <img src={props.imgPlay3} onClick={handleReset} alt="" />
        </div>
        <video
          ref={refC}
          src={props.urlV}
          className={props.clase}
        ></video>
      </div>
    </div>) : (<></>)}
  </div>
  );
}

export default ModalExpand;