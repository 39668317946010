import React, { Component } from "react";
import "./inicio.css";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let  ini;
    if (localStorage.getItem('Tema')=='bg-black') {
      ini='inicioNeg'
    }else{
      ini='inicio'
    }
    if(document.getElementById('columna2')){
            document.getElementById('columna2').classList.remove('d-none');
        }
    return (
      <main className={ini + " w-100 h-100 "}>
     
      </main>
    );
  }
}

export default Inicio;
