import React, { Component } from "react";
import { Button, Collapse, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./estructuraTexto.css";

class EstructuraTexto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      atras: false,
    };
  }

  onClickOpen = () => {
    this.setState({
      open: !this.state.open,
      atras: !this.state.atras,
    });
  };

  render(props) {
        if(document.getElementById('columna2')){
        document.getElementById('columna2').classList.remove('d-none');
        console.log("entre");
        }
        if(this.props.primero){
            var primero = "d-none primero-mobil";
        }else{
            var primero = "";
        }
        return (
      <div
        className={
          "widthPerTex " +
          this.props.movTxt +
          " " +
          this.props.movCV +
          " " +
          this.props.AlingText +
          " " +
          this.props.movContacto
        }
      >
        <div className="align-items-center tt d-none d-md-flex">
          {this.props.Titulo !== null && (
            <>
              <span className="simbolText">*</span>
            </>
          )}
          {this.props.LoquePienso == "movileStyle" && (
            <h2
              className={this.props.color + "  padinTodo  " + this.props.margen}
            >
              <span className="mr-1 terTxtFirst boldfa nombrestament">{this.props.Titulo2}</span>
            </h2>
          )}
          {this.props.Titulo2 !== null &&
            this.props.LoquePienso !== "movileStyle" && (
              <>
                <h2 className={this.props.color + " mrH  " + this.props.margen}>
                  {this.props.Titulo2}{""}
                </h2>
              </>
            )}

          <div className="d-flex align-items-center   ml-2 ">
            <h2 className={this.props.color + ' fuenTex '}>{this.props.Titulo} </h2>

            {this.props.Rojo !== null && (
              <>
                <span className={" ml-1 mr-1 fuenTex " + this.props.color}>
                    <i className="fa fa-asterisk" aria-hidden="true"></i>
                </span>
                <h2 className={this.props.color}> {this.props.Rojo} </h2>
                <span className={" ml-1 mr-1 fuenTex " + this.props.color}>
                    <i className="fa fa-asterisk" aria-hidden="true"></i>
                </span>

              </>
            )}

            {this.props.Rojo2 !== null && (
              <>
                <label className={" mb-2 ml-1 " + this.props.color}># </label>
                <h2 className={this.props.color}> {this.props.Rojo2}</h2>
                <label className={" mb-2 ml-1 " + this.props.color}># </label>
              </>
            )}

            {this.props.Verde !== null && (
              <>
                <h2 className={this.props.color}>{this.props.Verde}</h2>
              </>
            )}

            {(this.props.Azul !== null && this.props.Azul!=='' ) && (
              <>
                <label className={" mb-2 ml-1 " + this.props.color}># </label>
                <h2 className={this.props.color}> {this.props.Azul}</h2>
              </>
            )}
          </div>
        </div>
        {(this.props.TextoBG && !this.props.txtBusc )&& (
          <div
            className={"punto mb-2 "+primero+"  " + this.props.LoquePienso}
          ></div>
        )}

        {this.props.SubTitulo !== null && (
          <>
            <h2 className={this.props.color + " " + this.props.margen}>
              {this.props.SubTitulo}{" "}
            </h2>
          </>
        )}

        {this.props.cvTexto && (
          <div
            className={"d-flex align-items-center  " + this.props.mtH}
          >
            <h2 className={this.props.color1}>
              {!this.state.open && <label>  
                <i className="fas" 
                onClick={this.onClickOpen}
                aria-expanded={this.state.open}
                ><span className="textoflecha"  dangerouslySetInnerHTML={{ __html: this.props.texto }}></span>→</i>
              </label>}

              <Collapse in={this.state.open} className="">
                <div id="example-collapse-text" className={this.props.color1}>
                  {(this.props.ImgTexto1 !== null && this.props.ImgTexto1!==undefined) && (
                    <>
                      <Image
                        className="ImgTexto d-block"
                        src={this.props.ImgTexto1.default}
                      />
                    </>
                  )}
                  
                  {this.state.atras && (
                      <i className="fas ">
              <span
                      onClick={this.onClickOpen}
                      aria-controls="example-collapse-text"
                      aria-expanded={this.state.open}
                      className="textoflecha"  dangerouslySetInnerHTML={{ __html: this.props.texto + this.props.texto2 }}
                    >
                   
              </span>←</i>
                    
                  )}

                  {this.props.ImgTexto !== null && (
                    <>
                      <Image
                        className="ImgTexto d-block"
                        src={this.props.ImgTexto.default}
                      />
                    </>
                  )}
                </div>
              </Collapse>
            </h2>
          </div>
        )}

        {!this.props.cvTexto && (
          <div
            className={"align-items-center   " + this.props.mtH}
          >
            <h2 className={"    " + this.props.color1}>
              {!this.state.open && !this.props.Vinculo  && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.texto }}
                ></div>
              )}

              {!this.state.open && this.props.Vinculo && <a href={this.props.texto}>{this.props.texto}</a> }

              <Collapse in={this.state.open} className="">
                <div id="example-collapse-text" className={this.props.color1}>
                  {(this.props.ImgTexto1 !== null && this.props.ImgTexto1!==undefined) && (
                    <>
                      <Image
                        className="ImgTexto d-block"
                        src={this.props.ImgTexto1.default}
                      />
                    </>
                  )}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.texto + this.props.texto2,
                    }}
                  ></div>

                  {this.state.atras && (
                    <span
                      onClick={this.onClickOpen}
                      aria-controls="example-collapse-text"
                      aria-expanded={this.state.open}
                      className="p-3 ml-2"
                    >
                      <i className="fas">→</i>
                    </span>
                  )}

                  {(this.props.ImgTexto !== null &&  this.props.ImgTexto!==undefined )  && (
                    <>
                      <Image
                        className="ImgTexto d-block"
                        src={this.props.ImgTexto.default}
                      />
                    </>
                  )}
                </div>
              </Collapse>
            </h2>

            {!this.state.atras && !this.props.expanVal && (
              <h2
                onClick={this.onClickOpen}
                aria-controls="example-collapse-text"
                aria-expanded={this.state.open}
                className={" pb-2 pt-2 ml-2  " + this.props.flechaColor}
              >
                <i className="fas ">←</i>
              </h2>
            )}
          </div>
        )}
        {!this.props.TextoBG && <div className=" punto mb-2 ml-4 mr-2"></div>}
      </div>
    );
  }
}

export default withRouter(EstructuraTexto);