import React, { Component } from "react";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto";
import "./todoloquepienso.css";

class Todoloquepiensoyloqueno extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsStatement: [],
        };
    }
    componentDidMount() {
        var language = "https://mimi.zimbadigital.net/backend/getStatement.php";

        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({
                        lsStatement: resp,
                    });
                });
    }
    
    render() {
        return(
            this.state.lsStatement && this.state.lsStatement.map((item, i) => {
                if (localStorage.getItem('Lenguaje') == 'espanish') {
                    var titulo = item.titulo_es;
                    var texto = item.contenido_es;
                } else {
                    var titulo = item.titulo_en;
                    var texto = item.contenido_en;
                }
                return(
                        <main className=" d-flex  h-100 pt-2 curVerde bgTodo">
                            <div className="widthtodoloque textobajo1">
                                <EstructuraTexto
                                    margen={'margent'}
                                    flechaColor={'azul-flecha'}
                                    movTxt={'margenTodoloqueP'}
                                    Titulo={null}
                                    Titulo2={titulo}
                                    LoquePienso={'movileStyle'} 
                                    Rojo={null}
                                    Rojo2={null}
                                    expanVal={true}
                                    open={true}
                                    Verde={null}
                                    TextoBG={true}
                                    Azul={null}
                                    SubTitulo={null}
                                    texto={texto}
                                    texto2={``}
                        
                                    color="terTxtFirst"
                                    color1="colorNegro todolqs"
                                    ImgTexto={null}
                                    ImgTexto1={null}
                                    />
                            </div>
                        </main>
                        );
            })
            );
    }
}

export default Todoloquepiensoyloqueno;
